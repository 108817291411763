import { CommonModule } from '@angular/common';
import { Component, computed, model } from '@angular/core';
import {
  Button,
  FADE_IN,
  Icon,
  ModalAdapter,
  ModalContainer,
  SwiperDirective,
} from '@malirang/front-share';
import { Swiper, SwiperOptions } from 'swiper/types';

type GuideModalType = 'CREATE_CENTER' | 'FIND_CENTER' | 'CREATE_TICKET';

export type GuideModalProps = {
  type: GuideModalType;
};

type Item = {
  title: string;
  children: Child[];
};

type Child = {
  description: string;
  image: string;
  content: string;
  content2?: string;
  openGuide?: GuideModalType;
};

@Component({
  selector: 'app-guide',
  templateUrl: './guide.modal.html',
  imports: [ModalContainer, CommonModule, Button, SwiperDirective, Icon],
  animations: [FADE_IN],
})
export class GuideModal extends ModalAdapter<GuideModalProps> {
  type = computed(() => this.props()?.type);

  items: { [key: string]: Item } = {
    CREATE_CENTER: {
      title: '센터 생성',
      children: [
        {
          description: '센터 검색 페이지',
          image: 'guide/create-center-1.svg',
          content: '‘센터 생성’ 버튼을 눌러 새로운 센터를 생성할 수 있어요.',
          content2:
            'tip. 마이페이지 →소속 센터에서 ‘센터 생성’을 통해서도 가능해요.',
        },
        {
          description: '메인 홈 페이지',
          image: 'guide/create-center-2.svg',
          content: '센터 정보를 기입해주세요.',
        },
        {
          description: '프리랜서/사업자 유형 선택',
          image: 'guide/create-center-3.svg',
          content: '사업자 유형을 선택하세요.',
          content2:
            "tip. '사업자' 선택 시 사업자등록증이 필수이므로 미리 준비해주세요.",
        },
        {
          description: '센터 관리 페이지',
          image: 'guide/create-center-4.svg',
          content:
            '등록 완료 후, 센터 정보가 올바르게 반영되었는지 확인하세요.',
        },
      ],
    },
    FIND_CENTER: {
      title: '센터 찾기',
      children: [
        {
          description: '센터 찾기 가이드',
          image: 'guide/find-center-1.svg',
          content: "'센터 찾기' 버튼을 눌러 센터를 검색해 주세요.",
          content2:
            'tip. 마이페이지 →소속 센터 > 센터 찾기를 통해서도 가능해요.',
        },
        {
          description: '가입 신청',
          image: 'guide/find-center-2.svg',
          content: "검색창에 센터명을 입력한 후, '가입' 버튼을 눌러 주세요.",
        },
        {
          description: '가입 신청 취소',
          image: 'guide/find-center-3.svg',
          content: "'취소' 버튼을 눌러 가입 신청을 취소할 수 있어요.",
          content2:
            'tip. 마이페이지 → 소속 센터 → 가입 초대 내역에서도 취소할 수 있어요.',
        },
        {
          description: '센터 검색 페이지',
          image: 'guide/find-center-4.svg',
          content:
            "센터가 없다면, '신규 생성' 버튼을 눌러 새 센터를 만들어 주세요.",
          openGuide: 'CREATE_CENTER',
        },
      ],
    },
    CREATE_TICKET: {
      title: '발달 서비스 등록',
      children: [
        {
          description: '아동 관리 페이지',
          image: 'guide/create-ticket-1.svg',
          content: '‘아동 관리’에서 치료를 시작할 아동을 선택해주세요.',
        },
        {
          description: '아동 상세 페이지',
          image: 'guide/create-ticket-2.svg',
          content: `아동 상세페이지에서 '발달서비스 등록'을 통해 
          아동에게 발달 서비스를 부여할 수 있습니다.
          `,
        },
        {
          description: '발달 서비스 등록 페이지',
          image: 'guide/create-ticket-3.svg',
          content: `아동에게 부여할 발달 서비스를 선택한 후,
          담당 선생님 및 관련 정보를 입력해주세요.`,
        },
        {
          description: '아동 상세 페이지',
          image: 'guide/create-ticket-4.svg',
          content: '이제 등록된 발달 서비스의 치료 일정을 등록할 수 있어요!',
        },
      ],
    },
  };

  currentItem = computed(() => this.items[this.type()!]);
  currentChild = computed(() => this.currentItem()?.children[this.index()]);

  index = model(0);

  skip() {
    this.close();
  }

  prev() {
    if (this.index() === 0) {
      return;
    }

    this.index.set(this.index() - 1);
  }

  next() {
    if (this.index() === this.currentItem()?.children?.length - 1) {
      this.close();
      return;
    }

    this.index.set(this.index() + 1);
  }

  openGuide(type: string) {
    this.modalService.create(GuideModal, {
      componentProps: { type },
    });
  }
}
