@let color = type() === 'CREATE_TICKET' ? 'bg-[#DE954D]' : 'bg-primary';
@let logo = type() === 'CREATE_TICKET' ? 'logo4.svg' : 'logo3.svg';

<section modalContainer invisibleWhitespace class="w-[35rem] select-none">
  <div header [ngClass]="color" class="p-4 flex justify-end">
    <app-icon
      (click)="close()"
      name="mdi:close"
      class="text-white cursor-pointer"
    />
  </div>
  @if (currentItem(); as item) {
    <div
      [ngClass]="color"
      class="p-4 flex flex-col items-center xl:justify-center h-full xl:h-[40.5rem] overflow-hidden"
    >
      <div class="flex flex-col gap-8">
        <div class="flex flex-col gap-3 items-center">
          <img [src]="logo" class="max-h-10" />
          <p class="text-white text-2xl font-semibold">{{ item.title }}</p>
          <p class="text-white text-white/40">
            {{ currentChild()?.description }}
          </p>
        </div>
        @for (i of item.children; track $index) {
          @if ($index === index()) {
            <div class="w-full">
              <img @fadeIn [src]="i.image" class="object-cover" />
            </div>
          }
        }
      </div>
    </div>
  }
  <div footer>
    <div class="flex gap-3 items-center justify-center bg-white pt-4">
      @for (i of currentItem().children; track $index) {
        <div
          [ngClass]="$index === index() ? 'bg-primary' : 'bg-gray-200'"
          class="rounded-full size-2"
        ></div>
      }
    </div>
    <div
      class="min-h-[12rem] flex flex-col items-center justify-center bg-white w-full gap-3 pt-4 pb-10 px-2.5"
    >
      <p class="text-gray-600 text-lg">STEP. {{ index() + 1 }}</p>
      <p class="text-gray-800 font-medium whitespace-pre-line text-center">
        {{ currentChild()?.content }}
      </p>
      @if (currentChild()?.content2; as content2) {
        <p class="font-medium text-gray-500">{{ content2 }}</p>
      }
      @if (currentChild()?.openGuide; as guide) {
        <app-button
          (click)="openGuide(guide)"
          variant="outline"
          icon="material-symbols:book-2-rounded"
          size="sm"
          >센터 생성 가이드</app-button
        >
      }
    </div>
    <div class="p-4 flex w-full justify-between bg-white">
      <app-button (click)="skip()" variant="ghost" color="neutral"
        >건너뛰기</app-button
      >
      <div class="flex gap-2 items-center">
        @if (index()) {
          <app-button (click)="prev()" variant="ghost">< 이전</app-button>
        }
        <app-button (click)="next()" variant="ghost">다음 ></app-button>
      </div>
    </div>
  </div>
</section>
